<template>
<div class="template-full-width">




<div class="faq-content">
  <CgvMenuComponent :appView="appView" :language="language" page="faq" />

<div v-if="language=='fr'">

<div class="faq-category">
<div class="category-title">Informations générales</div>
<div class="category-content">
<div class="question">Si je fais livrer mon colis comme cadeau, le destinataire recevra-t-il la facture ?</div>
<div class="answer">Pour les commandes en France, nous envoyons systématiquement la facture par e-mail et la rendons disponible dans l'espace client, évitant ainsi son inclusion physique dans le colis pour des raisons écologiques. Pour les envois internationaux, les exigences douanières peuvent nécessiter une facture physique avec le colis.<br /><br />
  En cas de question ou de demande particulière concernant la facturation ou le contenu de votre colis, nous vous invitons à contacter notre service client. Vous avez également la possibilité de laisser une note spécifique au moment de finaliser votre commande lors du processus de checkout.
</div>
<div class="question">Mes informations personnelles et les données de ma carte bancaire sont-elles en sécurité sur votre site ?</div>
<div class="answer">
  Lorsque vous passez commande sur notre site, vous avez la possibilité de régler vos achats par carte bancaire ou via PayPal. Les paiements par carte bancaire sont sécurisés grâce aux plateformes de transactions protégées fournies par Stripe ou encore PayPal. Il est important de souligner que, dans le cadre du paiement par carte bancaire, nous n'avons pas accès aux données personnelles liées à votre moyen de paiement. Le règlement s'effectue directement auprès de l'institution bancaire. Vos informations personnelles et les données de votre carte bancaire sont donc sécurisées et protégées contre tout accès non autorisé.</div>
<div class="question">Où dois-je envoyer mon ordonnance ?</div>
<div class="answer">
  Lors de l'achat d'une paire de lunettes sur notre site, vous serez invité à fournir votre ordonnance directement sur la page du produit que vous configurez. Cette étape est essentielle uniquement si vous sélectionnez l'option de verres correcteurs pour vos lunettes. Vous aurez alors la possibilité de saisir manuellement les valeurs spécifiées dans votre ordonnance ou, si vous préférez, de nous envoyer une photo de celle-ci. Ce processus nous permet de personnaliser vos lunettes en fonction de vos besoins visuels, assurant ainsi un confort maximal.
</div>

</div>
</div>
<div class="faq-category">
<div class="category-title">Livraisons & Suivis</div>
<div class="category-content">
<div class="question">Comment savoir si ma commande a été expédiée ?</div>
<div class="answer">Vous pouvez consulter le statut de votre commande, dans votre profil. Il vous suffit de cliquez sur l’onglet “vos commandes”.
</div>
<div class="question">Où trouver la confirmation de ma commande?</div>
<div class="answer">La confirmation de votre commande vous sera envoyé par mail, pensez à bien vérifier vos spams.
</div>
<div class="question">Quels sont les pays où vous effectuez des livraisons ?</div>
<div class="answer">
  Nous sommes ravis d'offrir une livraison internationale à nos clients. Nous collaborons avec des transporteurs fiables pour assurer une livraison sûre et efficace dans une multitude de pays à travers le monde.
  <br /><br />
<span class="list-title" @click="displayList=!displayList">La liste complète des pays où nous livrons.</span>

<span v-if="displayList"><br /><br />
<i>*La liste des pays vers lesquels nous assurons la livraison dépend directement de nos prestataires de services de livraison, et il est possible que celle affichée sur notre site ne soit pas toujours à jour.* </i>
<br /><br />
AFGHANISTAN<br />
AFRIQUE DU SUD<br />
ALBANIE<br />
ALGERIE<br />
ALLEMAGNE<br />
ANGOLA<br />
ANGUILLA<br />
ANTIGUA-ET-BARBUDA<br />
ANTILLES NEERLANDAISES<br />
ARABIE SAOUDITE<br />
ARGENTINE<br />
ARMENIE<br />
ARUBA<br />
ASCENSION<br />
AUSTRALIE<br />
AUTRICHE<br />
AZERBAIJAN<br />
BAHAMAS<br />
BAHREIN<br />
BANGLADESH<br />
BARBADE<br />
BELARUS<br />
BELGIQUE<br />
BELIZE<br />
BENIN<br />
BERMUDES<br />
BHOUTAN<br />
BOLIVIE<br />
BONAIRE, SAINT-EUSTACHE ET SABA<br />
BOSNIE-HERZEGOVINE<br />
BOTSWANA<br />
BRESIL<br />
BRUNEI DARUSSALAM<br />
BULGARIE<br />
BURKINA FASO<br />
BURUNDI<br />
CAMBODGE<br />
CAMEROUN<br />
CANADA<br />
CAP-VERT<br />
CHILI<br />
CHINE<br />
CHYPRE<br />
COLOMBIE<br />
COMORES<br />
CONGO<br />
COREE DU SUD, REPUBLIQUE DE<br />
COSTA RICA<br />
COTE D'IVOIRE<br />
CROATIE<br />
CUBA<br />
CURACAO<br />
DANEMARK<br />
DJIBOUTI<br />
DOMINIQUE<br />
EGYPTE<br />
EL SALVADOR<br />
EMIRATS ARABES UNIS<br />
EQUATEUR<br />
ERYTHREE<br />
ESPAGNE<br />
ESTONIE<br />
ETATS-UNIS<br />
ETHIOPIE<br />
FIDJI<br />
FINLANDE<br />
GABON<br />
GAMBIE<br />
GEORGIE<br />
GEORGIE ET ILES SANDWICH DU SUD<br />
GHANA<br />
GIBRALTAR<br />
GRECE<br />
GRENADE<br />
GROENLAND<br />
GUAM<br />
GUATEMALA<br />
GUERNESEY<br />
GUINEE<br />
GUINEE EQUATORIALE<br />
GUINEE-BISSAU<br />
GUYANA<br />
HAITI<br />
HONDURAS<br />
HONG KONG<br />
HONGRIE<br />
ILE BOUVET<br />
ILE DE MAN<br />
ILE NORFOLK<br />
ILES ALAND<br />
ILES CAIMANES<br />
ILES CHRISTMAS<br />
ILES COCOS (KEELING)<br />
ILES COOK<br />
ILES FEROE<br />
ILES HEARD ET MC DONALD<br />
ILES MALOUINES (FALKLAND)<br />
ILES MARIANNES DU NORD<br />
ILES MARSHALL<br />
ILES MINEURES ELOIGNEES DES ETATS-U<br />
ILES SALOMON<br />
ILES TURQUES ET CAIQUES<br />
ILES VIERGES BRITANNIQUES<br />
ILES VIERGES DES ETATS-UNIS<br />
INDE<br />
INDONESIE<br />
IRAN (REPUBLIQUE ISLAMIQUE)<br />
IRAQ<br />
IRLANDE<br />
ISLANDE<br />
ISRAEL<br />
ITALIE<br />
JAMAIQUE<br />
JAPON<br />
JERSEY<br />
JORDANIE<br />
KAZAKHSTAN<br />
KENYA<br />
KIRGHIZISTAN<br />
KIRIBATI<br />
KOWEIT<br />
LAO, REPUBLIQUE DEMOCRATIQUE POPULA<br />
LESOTHO<br />
LETTONIE<br />
LIBAN<br />
LIBERIA<br />
LIBYE (JAMAHIRIYA ARABE LIBYENNE)<br />
LIECHTENSTEIN<br />
LITUANIE<br />
LUXEMBOURG<br />
MACAO<br />
MACEDOINE, L EX-REPUBLIQUE YOUGOSLA<br />
MADAGASCAR<br />
MALAISIE<br />
MALAWI<br />
MALDIVES<br />
MALI<br />
MALTE<br />
MAROC<br />
MAURICE<br />
MAURITANIE<br />
MEXIQUE<br />
MICRONESIE, ETATS FEDERES DE<br />
MOLDAVIE, REPUBLIQUE DE MOLDOVA<br />
MONGOLIE<br />
MONTENEGRO<br />
MONTSERRAT<br />
MOZAMBIQUE<br />
MYANMAR<br />
NAMIBIE<br />
NAURU<br />
NEPAL<br />
NICARAGUA<br />
NIGER<br />
NIGERIA<br />
NIUE<br />
NORVEGE<br />
NOUVELLE-ZELANDE<br />
OMAN<br />
OUGANDA<br />
OUZBEKISTAN<br />
PAKISTAN<br />
PALESTINE<br />
PANAMA<br />
PAPOUASIE-NOUVELLE-GUINEE<br />
PARAGUAY<br />
PAYS-BAS<br />
PEROU<br />
PHILIPPINES<br />
PITCAIRN<br />
POLOGNE<br />
PORTO RICO<br />
PORTUGAL<br />
QATAR<br />
REPUBLIQUE CENTRAFRICAINE<br />
REPUBLIQUE DEMOCRATIQUE DU CONGO<br />
REPUBLIQUE DOMINICAINE<br />
REPUBLIQUE TCHEQUE<br />
ROUMANIE<br />
ROYAUME-UNI<br />
RUSSIE, FEDERATION DE<br />
RWANDA<br />
SAHARA OCCIDENTAL<br />
SAINT-CHRISTOPHE-ET-NIEVES, SAINT-K<br />
SAINT-MARIN<br />
SAINT-MARTIN (PARTIE NEERLANDAISE)<br />
SAINT-VINCENT-ET-LES GRENADINES<br />
SAINTE-HELENE<br />
SAINTE-LUCIE<br />
SAMOA AMERICAINES<br />
SAO TOME-ET-PRINCIPE<br />
SENEGAL<br />
SERBIE<br />
SEYCHELLES<br />
SIERRA LEONE<br />
SINGAPOUR<br />
SLOVAQUIE<br />
SLOVENIE<br />
SOUDAN<br />
SOUDAN DU SUD<br />
SRI LANKA<br />
SUEDE<br />
SUISSE<br />
SURINAME<br />
SVALBARD ET ILE JAN MAYEN<br />
SWAZILAND<br />
TADJIKISTAN<br />
TAIWAN<br />
TANZANIE, REPUBLIQUE UNIE DE<br />
TCHAD<br />
TERRITOIRE BRITANNIQUE DE L OCEAN I<br />
THAILANDE<br />
TIMOR ORIENTAL, TIMOR-LESTE<br />
TOGO<br />
TOKELAU<br />
TONGA<br />
TRINITE-ET-TOBAGO<br />
TUNISIE<br />
TURKMENISTAN<br />
TURQUIE<br />
TUVALU<br />
UKRAINE<br />
URUGUAY<br />
VANUATU<br />
VATICAN SAINT-SIEGE (ETAT DU)<br />
VENEZUELA<br />
VIET NAM<br />
ZAMBIE<br />
ZIMBABWE
</span>

<br /><br />
</div>


  <div class="question">Qu’est-ce que le statut de ma commande ? Comment suivre cette dernière ?</div>
  <div class="answer">
    Vous pouvez accéder au statut actuel de votre commande dans votre profil, dans l'onglet "Vos commandes". Nous vous tiendrons également informé(e) par e-mail tout au long du processus de production.
  </div>

  <div class="question">Puis-je modifier l'adresse de livraison d'une commande?</div>
  <div class="answer">
    Si vous souhaitez modifier l'adresse de livraison de votre commande, nous vous invitons à contacter rapidement notre service client. Il est généralement possible de changer l'adresse tant que le statut de votre commande n'a pas été mis à jour en 'livraison'. Nos conseillers vérifieront le statut actuel de votre commande et vous informeront des options disponibles. Nous mettrons tout en œuvre pour accommoder votre demande et assurer la livraison de votre commande dans les meilleures conditions.
  </div>

  <div class="question">Quel est le délai de livraison pour ma commande?</div>
  <div class="answer">
    Le délai de livraison varie selon le produit et sera indiqué lors du paiement. 
  </div>

</div>
</div>
<div class="faq-category">
<div class="category-title">Politique de Retours et de Remboursements </div>
<div class="category-content">
<div class="question">Comment fonctionne votre politique de retour?</div>
<div class="answer">
  Les commandes passées sur notre site peuvent être retournées contre avoir ou remboursement jusqu’à un mois après achat.
<br />Cependant, le client ne pourra en aucun cas prétendre à l’exercice d’un quelconque droit de rétractation pour les commandes de tous les produits nettement personnalisés ou confectionnés selon leurs spécifications (ex : lunette de vue).
</div>
<div class="question">Puis-je être remboursé si je ne suis pas satisfait de mon achat?</div>
<div class="answer">
  Nous nous efforçons de garantir la satisfaction de nos clients avec chaque achat. Cependant, en raison de la nature personnalisée de nos lunettes, fabriquées spécifiquement pour chaque client, nous ne pouvons malheureusement pas accepter les retours ou offrir des remboursements en cas de non-satisfaction ou de changement d'avis. Nous vous encourageons à réviser attentivement votre commande et à nous contacter avant son placement en cas de questions ou de doutes.
</div>
<div class="question">Combien coûte l’expédition et les retours?</div>
<div class="answer">En cas de réception d'une commande avec des articles endommagés ou cassés, nous offrons un service de retour gratuit, sans frais supplémentaires.

</div>
<div class="question">Comment et quand recevrai-je mon remboursement ?</div>
<div class="answer">
  Votre remboursement sera effectué dès la réception du produit endommagé ou cassé dans nos ateliers. Ce dernier sera effectué par virement bancaire.
</div>

</div>
</div>
<div class="faq-category">
<div class="category-title">Garantie et satisfaction</div>
<div class="category-content">
<div class="question">J’ai cassé mes lunettes, sont-elles prises en charge?</div>
<div class="answer">Non, Visages ne couvre pas la casse ou le vol de vos lunettes. Cependant, vous pouvez contacter nos services sur l’email <a href="mailto:contact@visages.com">contact@visages.com</a>, nous serons ravis de trouver une solution de réparation pour vos lunettes.</div>
<div class="question">Ma monture a un défaut, que dois-je faire?</div>
<div class="answer">
  La qualité de nos produits est au cœur de nos préoccupations, et nous accordons une grande importance à la satisfaction de nos clients. Nous offrons une politique de retour sans frais pour les produits défectueux. Veuillez nous contacter pour initier le processus de retour. Après réception et vérification de la monture défectueuse, nous nous engageons à expédier la nouvelle paire que vous avez choisie dans les plus brefs délais, sans aucun frais supplémentaire.</div>


  <div class="question">Que faire si je ne suis pas satisfait(e) de mon sur-mesure?</div>
  <div class="answer">
    Nous garantissons l'ajustement de vos lunettes sur-mesure. Si votre première commande ne vous satisfait pas à 100%, nous fabriquerons un nouvel article à nos frais. Vous pouvez soumettre votre demande via la page contact, jusqu'à 10 jours après votre achat.  </div>

  <div class="question">Comment contacter le service client de Visages?</div>
  <div class="answer">
    Notre service clientèle est disponible du lundi au vendredi de 10h00 à 18h00. Vous pouvez nous contacter sur l’email <a href="mailto:contact@visages.com">contact@visages.com</a> ou bien sur le formulaire de la page contact en cliquant ici. Nous serons ravis de répondre à chacune de vos questions.
  </div>


</div>

</div>

<div class="faq-category">
<div class="category-title">Technologie, Processus et Produit</div>
<div class="category-content">
  <div class="question">Comment VISAGES assure-t-elle la précision des lunettes sur-mesure à distance ?</div>
  <div class="answer">
    VISAGES utilise un algorithme de scan 3D enrichi par Intelligence artificielle  pour garantir un ajustement parfait de chaque paire de lunettes. Après commande sur www.visages.com et le téléchargement de notre application mobile, un scan 3D de votre visage capture vos caractéristiques morphologiques avec une précision au dixième de millimètre. Cette technologie permet de créer des lunettes parfaitement ajustées, offrant confort et esthétique sur mesure.
    </div>
  <div class="question">L’algorithme de prise de mesure est-il précis?</div>
  <div class="answer">
    Oui, notre technologie de scan 3D, précise au dixième de millimètre, se distingue par sa fiabilité, sa robustesse et sa répétabilité. Elle surpasse les méthodes de mesure manuelles en éliminant l'erreur humaine, garantissant ainsi un ajustement, un confort et une esthétique optimale.
  </div>
  <div class="question">Quel est le processus pour commander une paire de lunettes sur-mesure?</div>
  <div class="answer">
    <ul>
                        <li >Sélection du modèle :&nbsp;Choisissez votre modèle préféré.</li>
                        <li >Personnalisation :&nbsp;Personnalisez votre paire (couleur, textures, branches, verres, gravures).</li>
                        <li >Sélection du mode sur-mesure :&nbsp;Sélectionnez le mode sur-mesure, puis ajoutez-le au panier et validez votre commande.</li>
                        <li >Scan du visage :&nbsp;Après l'achat, téléchargez notre application mobile pour effectuer le scan 3D.</li>
                        <li>Livraison :&nbsp;Recevez vos lunettes sur-mesure directement chez vous.</li>
                    </ul>
</div>
  <div class="question">Où est-ce que les lunettes sont fabriquées?</div>
  <div class="answer">
    Toutes nos paires de lunettes sont fièrement produites chez nos partenaires imprimeurs 3D, qui sont pour la grande majorité basés en France. Nous nous engageons à adopter une approche de production la plus locale possible, dans le but de minimiser notre impact environnemental. Ainsi, lorsque vous passez une commande depuis l'Europe, nous vous garantissons une production 100% européenne. Cette démarche s'inscrit dans notre volonté de soutenir l'économie locale tout en offrant à nos clients des produits de haute qualité, conçus dans le respect de l'environnement.
  </div>
  <div class="question">Comment fonctionne l’impression 3D?</div>
  <div class="answer">
    L'impression 3D est une méthode de fabrication additive qui, contrairement aux techniques traditionnelles soustractives, utilise précisément la matière nécessaire pour chaque produit fabriqué. Nous bénéficions d'une grande variété de technologies d'impression 3D (SLS, MJF, DLP, SLM...), et fabriquons ainsi à partir de matériaux biosourcés, de résines translucides, de nylons multicolores ou encore de titane. VISAGES vise à exploiter pleinement cette technologie pour permettre aux artistes et aux marques de publier librement leurs créations sur la plateforme visages.com, en expérimentant de nouveaux concepts. Produites en Europe, sans contraintes de stock, à l’unité, à la demande, et avec une garantie zéro déchet, ces lunettes représentent un mouvement révolutionnaire pour l’industrie lunetière que VISAGES souhaite étendre.
  </div>
  <div class="question">Quelles sont les différentes matières que propose Visages?</div>
  <div class="answer">
    Visages s'engage à offrir des montures alliant haute qualité et éco-responsabilité. Le PA11, bioplastique issu de l'huile de ricin, est notre choix privilégié pour sa contribution à la durabilité. Notre démarche d'innovation continue nous amène à enrichir régulièrement notre gamme avec des matériaux avant-gardistes, comme le titane et des résines translucides. Chez Visages, l'innovation technologique se conjugue avec la responsabilité environnementale pour choisir des matériaux toujours plus respectueux de notre planète.
  </div>
  <div class="question">Comment dois-je nettoyer mes lunettes imprimées en 3D?</div>
  <div class="answer">
    Pour assurer le nettoyage de vos lunettes veuillez suivre ces étapes :<br />
<ul>
  <li>1. Utilisez la microfibre fournie : Chaque paire de lunettes est accompagnée d'une chiffonnette VISAGES en microfibre spécialement conçu pour nettoyer les verres sans risquer de les rayer. Utilisez ce chiffon pour essuyer délicatement la surface des verres afin d'enlever les empreintes, la poussière, ou toute autre saleté.</li>
  <li>2. Nettoyage à l'eau tiède : Si vos lunettes nécessitent un nettoyage plus approfondi, vous pouvez les rincer délicatement sous un filet d'eau tiède. Cela aide à éliminer les particules de saleté qui pourraient causer des rayures pendant le nettoyage. Après le rinçage, séchez les verres et la monture avec le chiffon en microfibre fourni, en évitant l'utilisation de tissus rugueux, de serviettes en papier, ou de vêtements qui peuvent abîmer les verres ou laisser des peluches.</li>
</ul>
<br />
En suivant ces recommandations, vous prolongerez la durée de vie de vos lunettes et maintiendrez une vision claire et nette. Pour toute question supplémentaire ou si vous rencontrez des difficultés lors du nettoyage de vos lunettes, n'hésitez pas à contacter notre service client pour obtenir de l'aide.
</div>

<div class="question">Comment déchiffrer mon ordonnance?</div>
  <div class="answer">
    Il existe différentes façons de rédiger une ordonnance, mais les règles sont simples et communes.
    <br /><br />
D’abord, on distingue les deux yeux : <br />
OD = Oeil droit OG = Oeil gauche
<br /><br />
Puis on ajoute une à trois valeurs par oeil : la sphère, le cylindre et l’axe:
<br /><br />
Sphère = chiffre positif ou négatif, jamais entre parenthèses.<br />
Cylindre = chiffre positif ou négatif, toujours entre parenthèses. <br />
Axe = chiffre toujours accompagné du signe degré “ ° ” <br />
<br />
Il est possible qu’il n’y ait écrit qu’une ou deux valeurs par oeil, par exemple : “OD = +1,75” Cela veut dire que les deux autres valeurs sont égales à 0.
<br /><br />
À savoir : si vous avez la mention Addition ou Add, c’est que vous avez besoin de verres progressifs.
<br /><br />
Si vous avez un doute, une question, ou besoin de plus d’explications, n’hésitez pas à nous contacter sur l’adresse email <a href="mailto:contact@visages.com">contact@visages.com</a> Nous vous aiderons et conseillerons avec plaisir.
</div>

<div class="question">JE N’AI PAS D’ORDONNANCE MAIS JE CONNAIS MES CORRECTIONS, POUVEZ-VOUS ME FAIRE DES LUNETTES À MA VUE ?</div>
  <div class="answer">
    Oui, nous pouvons fabriquer vos verres directement à partir de vos corrections.
    <br />A savoir : vous aurez besoin d’une ordonnance datant de moins de 3 ans si vous souhaitez vous faire rembourser par la Sécurité Sociale et votre mutuelle.
</div>

<div class="question">POUVEZ-VOUS ME FAIRE UNE NOUVELLE ORDONNANCE ?</div>
  <div class="answer">
    Non, en France seuls les médecins ophtalmologistes sont habilités à délivrer une ordonnance.
    <br /><br />
Notre application mobile IOS VISAGES emploie un procédé de prise de mesures morphologiques ainsi que la prise de mesures de votre écart pupillaire et hauteur pupillaire. Si vous souhaitez commander des verres de vue/avec correction, il est de votre ressort de nous fournir votre correction ou ordonnance.
</div>

<div class="question">QU’EST CE QUE L’ÉCART PUPILLAIRE ?</div>
  <div class="answer">
    C’est la mesure entre le centre de vos deux pupilles.
    <br />Si vous achetez vos lunettes à partir de notre application mobile IOS VISAGES, lors du scan de votre visage nous prenons le soin d’effectuer la prise de mesures de votre écart pupillaire ainsi que de votre hauteur pupillaire, pour un ajustement optique et morphologique idéal.
</div>

<div class="question">A quoi sert la mesure de l'écart pupillaire ?
</div>
  <div class="answer">
    La mesure de l'écart pupillaire sert à déterminer la distance entre les centres des pupilles de vos yeux. Elle permet de centrer vos verres correcteurs par rapport au centre de vos pupilles. Cette mesure est importante car elle garantit un confort de vision optimal.
  </div>

<div class="question">COMMENT OBTENIR MON ÉCART PUPILLAIRE ?</div>
  <div class="answer">

Lorsque vous achetez vos lunettes sur <a href="https://www.visages.com" target="_blank">www.visages.com</a>, un scan de votre visage permet de mesurer automatiquement et avec précision votre écart pupillaire ainsi que votre hauteur pupillaire, assurant ainsi un ajustement optique et morphologique parfait. Ce scan nécessite un appareil iOS (iPhone ou iPad) muni du FaceID.
<br /><br />
Si vous ne disposez pas d’un appareil iOS avec FaceID et souhaitez commander sur notre site, voici vos options :
<br />
<ul><li>Méthode 1 : Consultez votre dernière facture d’opticien. Les termes “Ec” ou “EP” indiquent votre écart pupillaire.</li>
<li>Méthode 2 : Sollicitez un ami ou un membre de la famille avec un appareil iOS équipé du FaceID pour télécharger l’application mobile VISAGES. Après téléchargement, entrez votre email et votre numéro de commande pour procéder au scan de votre visage. Toutes les mesures nécessaires seront automatiquement intégrées à la fabrication de vos lunettes.

</li></ul>
  <br />
En cas de questions, notre équipe est à votre disposition via <a href="mailto:contact@visages.com">contact@visages.com</a>.
</div>
</div>

		</div>
  </div>


<div v-else>

<div class="faq-category">
<div class="category-title">General Information</div>
<div class="category-content">
<div class="question">Should I have my parcel delivered as a gift, will the recipient receive the invoice?</div>
<div class="answer">
  For orders within France, we systematically email the invoice and make it accessible in the customer account, thereby avoiding its physical inclusion in the parcel for sustainability reasons. For international shipments, customs requirements may necessitate a physical invoice with the parcel.
<br /><br />
Should you have any inquiries or specific requests regarding billing or the contents of your parcel, we invite you to contact our customer service. You also have the option to leave a specific note when finalizing your order during the checkout process.
</div>
<div class="question">Are my personal details and credit card information secure on your website?</div>
<div class="answer">
  When placing an order on our website, you have the option to pay for your purchases with a credit card or via PayPal. Credit card payments are secured through protected transaction platforms provided by Stripe or PayPal. It is crucial to note that, in the case of credit card payments, we do not have access to the personal data associated with your payment method. Payment is made directly to the banking institution. Therefore, your personal information and credit card data are secure and safeguarded against any unauthorized access.
</div>
<div class="question">Where should I submit my prescription?</div>
<div class="answer">
  When purchasing a pair of glasses from our website, you will be prompted to provide your prescription directly on the product page you are configuring. This step is mandatory only if you select the option for prescription lenses for your glasses. You will then have the option to manually enter the values specified in your prescription or, if you prefer, to send us a photo of it. This process enables us to customize your glasses according to your visual needs, thereby ensuring maximum comfort. 
</div>

</div>
</div>
<div class="faq-category">
<div class="category-title">Deliveries & Tracking</div>
<div class="category-content">
<div class="question">How can I determine if my order has been shipped?</div>
<div class="answer">
  You can check the status of your order in your profile. Simply click on the “Your Orders” tab.
</div>
<div class="question">Where can I find my order confirmation?</div>
<div class="answer">
  Your order confirmation will be sent to you via email. Please remember to check your spam folder.
</div>
<div class="question">Which countries do you deliver to?</div>
<div class="answer">
  We are pleased to offer international shipping to our customers. We partner with reliable carriers to ensure safe and efficient delivery to a wide range of countries around the globe.
  <br /><br />
<span class="list-title" @click="displayList=!displayList">The complete list of countries we deliver to:</span>

<span v-if="displayList"><br /><br />
<i>The list of countries we deliver to directly depends on our delivery service providers, and it's possible that the list displayed on our site may not always be up to date.</i>
<br /><br />
AFGHANISTAN<br />
AFRIQUE DU SUD<br />
ALBANIE<br />
ALGERIE<br />
ALLEMAGNE<br />
ANGOLA<br />
ANGUILLA<br />
ANTIGUA-ET-BARBUDA<br />
ANTILLES NEERLANDAISES<br />
ARABIE SAOUDITE<br />
ARGENTINE<br />
ARMENIE<br />
ARUBA<br />
ASCENSION<br />
AUSTRALIE<br />
AUTRICHE<br />
AZERBAIJAN<br />
BAHAMAS<br />
BAHREIN<br />
BANGLADESH<br />
BARBADE<br />
BELARUS<br />
BELGIQUE<br />
BELIZE<br />
BENIN<br />
BERMUDES<br />
BHOUTAN<br />
BOLIVIE<br />
BONAIRE, SAINT-EUSTACHE ET SABA<br />
BOSNIE-HERZEGOVINE<br />
BOTSWANA<br />
BRESIL<br />
BRUNEI DARUSSALAM<br />
BULGARIE<br />
BURKINA FASO<br />
BURUNDI<br />
CAMBODGE<br />
CAMEROUN<br />
CANADA<br />
CAP-VERT<br />
CHILI<br />
CHINE<br />
CHYPRE<br />
COLOMBIE<br />
COMORES<br />
CONGO<br />
COREE DU SUD, REPUBLIQUE DE<br />
COSTA RICA<br />
COTE D'IVOIRE<br />
CROATIE<br />
CUBA<br />
CURACAO<br />
DANEMARK<br />
DJIBOUTI<br />
DOMINIQUE<br />
EGYPTE<br />
EL SALVADOR<br />
EMIRATS ARABES UNIS<br />
EQUATEUR<br />
ERYTHREE<br />
ESPAGNE<br />
ESTONIE<br />
ETATS-UNIS<br />
ETHIOPIE<br />
FIDJI<br />
FINLANDE<br />
GABON<br />
GAMBIE<br />
GEORGIE<br />
GEORGIE ET ILES SANDWICH DU SUD<br />
GHANA<br />
GIBRALTAR<br />
GRECE<br />
GRENADE<br />
GROENLAND<br />
GUAM<br />
GUATEMALA<br />
GUERNESEY<br />
GUINEE<br />
GUINEE EQUATORIALE<br />
GUINEE-BISSAU<br />
GUYANA<br />
HAITI<br />
HONDURAS<br />
HONG KONG<br />
HONGRIE<br />
ILE BOUVET<br />
ILE DE MAN<br />
ILE NORFOLK<br />
ILES ALAND<br />
ILES CAIMANES<br />
ILES CHRISTMAS<br />
ILES COCOS (KEELING)<br />
ILES COOK<br />
ILES FEROE<br />
ILES HEARD ET MC DONALD<br />
ILES MALOUINES (FALKLAND)<br />
ILES MARIANNES DU NORD<br />
ILES MARSHALL<br />
ILES MINEURES ELOIGNEES DES ETATS-U<br />
ILES SALOMON<br />
ILES TURQUES ET CAIQUES<br />
ILES VIERGES BRITANNIQUES<br />
ILES VIERGES DES ETATS-UNIS<br />
INDE<br />
INDONESIE<br />
IRAN (REPUBLIQUE ISLAMIQUE)<br />
IRAQ<br />
IRLANDE<br />
ISLANDE<br />
ISRAEL<br />
ITALIE<br />
JAMAIQUE<br />
JAPON<br />
JERSEY<br />
JORDANIE<br />
KAZAKHSTAN<br />
KENYA<br />
KIRGHIZISTAN<br />
KIRIBATI<br />
KOWEIT<br />
LAO, REPUBLIQUE DEMOCRATIQUE POPULA<br />
LESOTHO<br />
LETTONIE<br />
LIBAN<br />
LIBERIA<br />
LIBYE (JAMAHIRIYA ARABE LIBYENNE)<br />
LIECHTENSTEIN<br />
LITUANIE<br />
LUXEMBOURG<br />
MACAO<br />
MACEDOINE, L EX-REPUBLIQUE YOUGOSLA<br />
MADAGASCAR<br />
MALAISIE<br />
MALAWI<br />
MALDIVES<br />
MALI<br />
MALTE<br />
MAROC<br />
MAURICE<br />
MAURITANIE<br />
MEXIQUE<br />
MICRONESIE, ETATS FEDERES DE<br />
MOLDAVIE, REPUBLIQUE DE MOLDOVA<br />
MONGOLIE<br />
MONTENEGRO<br />
MONTSERRAT<br />
MOZAMBIQUE<br />
MYANMAR<br />
NAMIBIE<br />
NAURU<br />
NEPAL<br />
NICARAGUA<br />
NIGER<br />
NIGERIA<br />
NIUE<br />
NORVEGE<br />
NOUVELLE-ZELANDE<br />
OMAN<br />
OUGANDA<br />
OUZBEKISTAN<br />
PAKISTAN<br />
PALESTINE<br />
PANAMA<br />
PAPOUASIE-NOUVELLE-GUINEE<br />
PARAGUAY<br />
PAYS-BAS<br />
PEROU<br />
PHILIPPINES<br />
PITCAIRN<br />
POLOGNE<br />
PORTO RICO<br />
PORTUGAL<br />
QATAR<br />
REPUBLIQUE CENTRAFRICAINE<br />
REPUBLIQUE DEMOCRATIQUE DU CONGO<br />
REPUBLIQUE DOMINICAINE<br />
REPUBLIQUE TCHEQUE<br />
ROUMANIE<br />
ROYAUME-UNI<br />
RUSSIE, FEDERATION DE<br />
RWANDA<br />
SAHARA OCCIDENTAL<br />
SAINT-CHRISTOPHE-ET-NIEVES, SAINT-K<br />
SAINT-MARIN<br />
SAINT-MARTIN (PARTIE NEERLANDAISE)<br />
SAINT-VINCENT-ET-LES GRENADINES<br />
SAINTE-HELENE<br />
SAINTE-LUCIE<br />
SAMOA AMERICAINES<br />
SAO TOME-ET-PRINCIPE<br />
SENEGAL<br />
SERBIE<br />
SEYCHELLES<br />
SIERRA LEONE<br />
SINGAPOUR<br />
SLOVAQUIE<br />
SLOVENIE<br />
SOUDAN<br />
SOUDAN DU SUD<br />
SRI LANKA<br />
SUEDE<br />
SUISSE<br />
SURINAME<br />
SVALBARD ET ILE JAN MAYEN<br />
SWAZILAND<br />
TADJIKISTAN<br />
TAIWAN<br />
TANZANIE, REPUBLIQUE UNIE DE<br />
TCHAD<br />
TERRITOIRE BRITANNIQUE DE L OCEAN I<br />
THAILANDE<br />
TIMOR ORIENTAL, TIMOR-LESTE<br />
TOGO<br />
TOKELAU<br />
TONGA<br />
TRINITE-ET-TOBAGO<br />
TUNISIE<br />
TURKMENISTAN<br />
TURQUIE<br />
TUVALU<br />
UKRAINE<br />
URUGUAY<br />
VANUATU<br />
VATICAN SAINT-SIEGE (ETAT DU)<br />
VENEZUELA<br />
VIET NAM<br />
ZAMBIE<br />
ZIMBABWE
</span>

<br /><br />
</div>


  <div class="question">What is the status of my order? How can I track it?</div>
  <div class="answer">
    You can access the current status of your order in your profile, under the "Your Orders" tab. We will also keep you informed via email throughout the production process.
  </div>

  <div class="question">Can I change the delivery address of an order?</div>
  <div class="answer">
    If you wish to change the delivery address for your order, we encourage you to promptly contact our customer service. It is usually possible to change the address as long as the status of your order has not been updated to 'shipped'. Our team will check the current status of your order and inform you of the available options. We will make every effort to accommodate your request and ensure the delivery of your order under the best conditions.
  </div>

  <div class="question">What is the delivery time for my order?</div>
  <div class="answer">
    The delivery time varies depending on the product and will be indicated at checkout.
  </div>

</div>
</div>
<div class="faq-category">
<div class="category-title">Returns and Refunds Policy </div>
<div class="category-content">
<div class="question">How does your return policy work?</div>
<div class="answer">
  Orders placed on our site can be returned for store credit or a refund up to one month after purchase.
<br />However, customers cannot exercise any right of withdrawal for orders of products that are clearly personalised or made to their specifications (e.g., made to fit, prescription glasses).
</div>
<div class="question">Can I be refunded if I am not satisfied with my purchase?</div>
<div class="answer">
  We guarantee the fit of your custom-made glasses. If your first order does not satisfy you 100%, we will manufacture a new item at our expense. You can submit your request via the contact page, up to 10 days after your purchase.
</div>
<div class="question">What is the cost of shipping and returns?</div>
<div class="answer">
  In the event of receiving an order with damaged or broken items, we offer a free return service, with no additional charges.
</div>
<div class="question">How and when will I receive my refund?</div>
<div class="answer">
  
  Your refund will be processed as soon as the damaged or broken product is received in our workshop. It will be issued via bank transfer.
</div>

</div>
</div>
<div class="faq-category">
<div class="category-title">Warranty and Satisfaction</div>
<div class="category-content">
<div class="question">I broke my glasses, are they covered?</div>
<div class="answer">
  No, Visages does not cover breakage or theft of your glasses. However, you can contact our services at hello@visages.com, and we will be delighted to find a repair solution for your glasses.

</div>
<div class="question">My frame has a defect, what should I do?</div>
<div class="answer">
  The quality of our products is at the heart of our concerns, and we place great importance on customer satisfaction. We offer a no-cost return policy for defective products. Please contact us to initiate the return process. Upon receipt and inspection of the defective frame, we commit to shipping the new pair you have chosen as quickly as possible, at no additional charge.  
</div>


  <div class="question">What if I am not satisfied with my custom-made order?</div>
  <div class="answer">
   
    We place great importance on the fit of your custom-made glasses and the guarantee of our custom-fit service. We have so much confidence in our custom-fit algorithm that if the fit of your first order does not satisfy you 100%, we commit to manufacturing a new item, at our expense. You have the option to submit your request here, up to 1 month after your purchase. Our priority is to ensure your total satisfaction with glasses that fit you perfectly.
  </div>

  <div class="question">How to contact Visages customer service?</div>
  <div class="answer">
    Our customer service is available from Monday to Friday, from 10:00 AM to 6:00 PM CET. You can contact us at hello@visages.com or on the contact page form by clicking here. We will be delighted to answer each of your questions.  
  </div>


</div>

</div>

<div class="faq-category">
<div class="category-title">Technology, Process, and Product</div>
<div class="category-content">
  <div class="question">How does VISAGES ensure the precision of custom glasses remotely?</div>
  <div class="answer">
    VISAGES uses a 3D scanning algorithm enhanced by artificial intelligence to ensure a perfect fit for each pair of glasses. After ordering on www.visages.com and downloading our mobile app, a 3D scan of your face captures your unique features with 1/10th of a millimeter accuracy. This technology creates perfectly fitted glasses, offering both comfort and custom aesthetics.
  </div>
  <div class="question">Is the measurement algorithm accurate?</div>
  <div class="answer">
    Yes, our 3D scanning technology, accurate to the tenth of a millimeter, is distinguished by its reliability, robustness, and repeatability. It surpasses manual measurement methods by eliminating human error, thus ensuring optimal fit, comfort, and aesthetics.
  </div>
  <div class="question">What is the process for ordering a pair of custom glasses?</div>
  <div class="answer">
    <ul>
      <li >Model selection: Choose your favorite model.</li>
                        <li >Customisation: Customize your pair (color, textures, temples, lenses, engravings).</li>
                        <li >Made-to-fit option: Select the made-to-fit option, then add it to your cart and complete your order.</li>
                        <li >Face scan: After purchase, download our mobile app to perform the 3D scan.</li>
                        <li>Delivery: Receive your made-to-fit glasses directly at home.</li>
</ul>
</div>
  <div class="question">Where are the glasses produced?</div>
  <div class="answer">
    All our pairs of glasses are proudly produced by our 3D printing partners, who are mostly based in France. We commit to adopting the most local production approach possible, aiming to minimize our environmental impact. Thus, when you place an order from Europe, we guarantee a 100% European production. This approach is part of our commitment to support the local economy while offering our customers high-quality products, designed with environmental respect in mind.
  </div>
  <div class="question">How does 3D printing work?</div>
  <div class="answer">
   
    3D printing is an additive manufacturing method that, unlike traditional subtractive techniques, uses precisely the material needed for each product. We benefit from a wide variety of 3D printing technologies (SLS, MJF, DLP, SLM...), and thus manufacture using biosourced materials, translucent resins, multicolored nylons, and even titanium. VISAGES aims to fully harness this technology to allow artists and brands to freely publish their creations on the visages.com platform, experimenting with new concepts. Produced in Europe, without stock constraints, individually, on-demand, and with a zero-waste guarantee, these glasses represent a revolutionary movement for the eyewear industry that VISAGES seeks to expand.
  </div>
  <div class="question">What materials does Visages offer?</div>
  <div class="answer">
    Visages is committed to offering frames that combine high quality and eco-responsibility. PA11, a bioplastic derived from castor oil, is our preferred choice for its contribution to sustainability. Our continuous innovation approach regularly enriches our range with avant-garde materials, such as titanium and translucent resins. At Visages, technological innovation is combined with environmental responsibility to choose materials that are increasingly respectful of our planet.   
  </div>
  <div class="question">How should I clean my 3D-printed glasses?</div>
  <div class="answer">
    To ensure the cleaning of your glasses, please follow these steps:<br />
<ul>
  <li>1. Use the provided microfiber: Each pair of glasses comes with a VISAGES microfiber cloth specially designed to clean lenses without risking scratches. Use this cloth to gently wipe the surface of the lenses to remove fingerprints, dust, or any other dirt.</li>
  <li>2. Cleaning with warm water: If your glasses require a more thorough cleaning, you can gently rinse them under a stream of warm water. This helps to remove dirt particles that could cause scratches during cleaning. After rinsing, dry the lenses and frame with the provided microfiber cloth, avoiding the use of rough fabrics, paper towels, or clothing that can damage the lenses or leave lint.</li>
</ul>
<br />
Following these recommendations will extend the life of your glasses and maintain clear and sharp vision. For any additional questions or if you encounter difficulties when cleaning your glasses, do not hesitate to contact our customer service for assistance.
</div>

<div class="question">How to understand my prescription?</div>
  <div class="answer">
    There are different ways to write a prescription, but the rules are simple and common.
    <br /><br />

First, the two eyes are distinguished:<br />
OD = Right eye OG = Left eye
<br /><br />
Then one to three values per eye are added: the sphere, cylinder, and axis:
<br /><br />

Sphere = positive or negative number, never in parentheses.<br />
Cylinder = positive or negative number, always in parentheses.<br />
Axis = number always accompanied by the degree sign “°”
<br />
<br />
It is possible that only one or two values per eye are written, for example: 
“OD = +1.75” 
This means that the other two values are equal to 0.
<br /><br />
Note: if you see the mention Addition or Add, it means you need progressive lenses.
<br />If you have any doubts, questions, or need more explanations, do not hesitate to contact us at hello@visages.com. We will be happy to help and advise you.
</div>

<div class="question">I don't have a prescription but I know my corrections, can you make glasses to my specifications?</div>
  <div class="answer">
    Yes, we can manufacture your lenses directly from your corrections.
<br />Please note: you will need a prescription dated less than 3 years old if you wish to be reimbursed by Social Security and your health insurance.
</div>

<div class="question">Can you provide me with a new prescription?</div>
  <div class="answer">
    No, in France, only ophthalmologists are authorised to issue a prescription.
    <br /><br />
    Our IOS VISAGES mobile app uses a process to take morphological measurements as well as measuring your pupillary distance and pupillary height. If you wish to order prescription/corrective lenses, it is your responsibility to provide us with your correction or prescription.
  </div>

<div class="question">What is the pupillary distance?</div>
  <div class="answer">
    It is the measurement between the centers of your two pupils.
    <br />
If you purchase your glasses using our IOS VISAGES mobile app, during the scan of your face, we take care to measure your pupillary distance as well as your pupillary height, for an ideal optical and morphological fit.

 
</div>

<div class="question">What is the purpose of measuring the pupillary distance?
</div>
  <div class="answer">
    
    The measurement of the pupillary distance is used to determine the distance between the centers of the pupils of your eyes. It allows your corrective lenses to be centered in relation to the center of your pupils. This measurement is important because it guarantees optimal visual comfort.
  </div>

<div class="question">How do I obtain my pupillary distance?</div>
  <div class="answer">

    When you purchase your glasses on www.visages.com, a scan of your face allows for the automatic and precise measurement of your pupillary distance as well as your pupillary height, thus ensuring a perfect optical and morphological fit. This scan requires an iOS device (iPhone or iPad) equipped with FaceID.
    <br /><br />
    If you do not have an iOS device with FaceID and wish to order on our site, here are your options:<br />
<ul><li>Method 1: Check your last optician's invoice. The terms "Ec" or "EP" indicate your pupillary distance.</li>
<li>Method 2: Ask a friend or family member with an iOS device equipped with FaceID to download the VISAGES mobile app. After downloading, enter your email and order number to proceed with the scan of your face. All necessary measurements will be automatically integrated into the manufacturing of your glasses.
</li></ul>
  <br />
  If you have any questions, our team is available at hello@visages.com..
</div>
</div>

		</div>
  </div>
</div>
</div>


    <br /><br /><br /><br />
    <Footer v-if="appView==false" :displayModalMenu="displayModalMenu" :language="language" />
  
  <div v-if="displayDeliveryPopup" @click="closeModal($event)" class="delivery-popup">
    <div class="content">
      
      <span id="cross" @click="displayDeliveryPopup=false" data-v-7ba5bd90=""><img src="../../public/assets/images/2021/05/iconfinder_Close_1031533-1.png" width="12" data-v-7ba5bd90=""></span>
      <center><input class="input" type="text" v-model="delivery" placeholder="N° de suivi" /> <button @click="searchDelivery()" class="button">SUIVRE MON COLIS</button>
      </center>
    </div>
  </div>
  
  
</template>

<script>

import Footer from '../components/layout/Footer.vue'
import jQuery  from 'jquery';

import CgvMenuComponent from "../components/children/CGV/CgvMenuComponent.vue" 
import { useMeta } from 'vue-meta'

export default {
  name: 'Faq',
  components: {
    Footer,
    CgvMenuComponent
  },
  data() {
    return {
      displayList:false,
      controller: null,
      tween: null,
      scene: null,
      delivery:'',
      displayDeliveryPopup:false
    }
    },
    watch:{
        
        language:  function () {
          setTimeout(function(){
            jQuery('.faq-category .category-title').click(function(){
              
              if(jQuery(this).parent().find('.category-content').is(':visible'))
              {
                  jQuery(this).parent().find('.category-content').slideUp();
                  jQuery(this).removeClass('open');
              }
              else
              {
                  jQuery(this).parent().find('.category-content').slideDown();
                  jQuery(this).addClass('open');
              }
              
          });
          
          jQuery('.faq-category .question').click(function(){
              
              if(jQuery(this).next('.answer').is(':visible'))
              {
                  jQuery(this).next('.answer').slideUp();
                  jQuery(this).removeClass('open');
              }
              else
              {
                  jQuery(this).addClass('open');
                  jQuery(this).next('.answer').slideDown();
              }
              
          });
          },100)
        },
      },
    methods:{
      closeModal($event)
      {
          if (jQuery($event.target).parents('.content').length) {

        }
          else
          {
              this.displayDeliveryPopup=false
          }
      },
      searchDelivery()
      {
        window.open('https://www.laposte.fr/outils/suivre-vos-envois?code='+this.delivery, '_blank');
      }
    },
    props:["displayModalMenu","language","appView"],

  created() {


    useMeta({
    title: "VISAGES - FAQ",
    
    htmlAttrs: { lang: this.language, amp: true },
    
})

   
    if(this.language=="fr")
    {
      document.querySelector('meta[name="description"]').setAttribute('content',"Foire aux questions VISAGES.")
    }
    else
    {
      document.querySelector('meta[name="description"]').setAttribute('content',"VISAGES FAQ.")
      
    }
    

  },
  mounted()
  {
     jQuery(document).ready(function(){
								
        jQuery('.faq-category .category-title').click(function(){
            
            if(jQuery(this).parent().find('.category-content').is(':visible'))
            {
                jQuery(this).parent().find('.category-content').slideUp();
                jQuery(this).removeClass('open');
            }
            else
            {
                jQuery(this).parent().find('.category-content').slideDown();
                jQuery(this).addClass('open');
            }
            
        });
        
        jQuery('.faq-category .question').click(function(){
            
            if(jQuery(this).next('.answer').is(':visible'))
            {
                jQuery(this).next('.answer').slideUp();
                jQuery(this).removeClass('open');
            }
            else
            {
                jQuery(this).addClass('open');
                jQuery(this).next('.answer').slideDown();
            }
            
        });
        
    });
  }
  
}
</script>
<style scoped>
.list-title
{
  cursor:pointer;
  border-bottom:1px solid black;
}
@media screen and (max-width:760px)
{
  body .faq-category:nth-child(2) .category-title
{
  border-top:0 !important;
}
  body .faq-category .category-title::after
  {

    top:50% !important;
    transform: translateY(-50%) !important;
  }
.category-content {
  padding-left:0px !important;
}
  body .faq-category .category-title {
    font-size: 14px !important;
    padding-left:50px !important;
    padding-top:20px !important;
    padding-bottom:20px !important;
  }
  body .faq-category .category-title::before {
    width:35px !important;
    height:35px !important;
    top : 12px !important;
  }
}
.category-content {
  padding-left:80px;
}
body .faq-category .category-title.open::after
{
  content:"-";
}
body .faq-category .category-title::after
{
	position:absolute;
	top:35px;
  font-weight: 400 !important;
	transition:none;
  font-size:18px;
	right:10px;
	content:'+';
	transform: none;
}
body .faq-category .category-title.open::before
{
  
  background-image:url("../../public/assets/images/arrow2.png");
}
body .faq-category .category-title::before
{
  content:" ";
  position:absolute;
  left:0;
  top:17px;
  width:56px;
  height:56px;
  background-size:100% 100%;
  background-image:url("../../public/assets/images/arrow5.png");
}
body .faq-category .category-title.open {
  font-weight: 600;
}
body .faq-category:nth-child(2) .category-title
{
  border-top:1px solid #ccc;
}
body .faq-category .category-title
{
	position:relative;
  padding-left:80px;
	cursor:pointer;
  line-height: 1;
	font-weight:400;
	padding-bottom:35px;
	padding-top:35px;
  text-transform: none;
  font-size:18px;
	border-bottom:1px solid #ccc;
}

body .faq-content
{
	padding-left:15%;
	padding-right:15%;
	padding-top:50px;
	padding-bottom:50px;
	min-height:286px;
	display:block;
	vertical-align:top;
	width:100% !important;
	
}
.faq-content
{
	margin-top:50px !important;
}
a 
{
color:#000;
}
</style>