export default {
   menu: [
    {
        text: "Détails du compte",
        text_en: "Account details",
        link: "accountDetails",
        class: "account",
        img: "account-icon1.png"
    },
    {
        text: "Commandes",
        text_en: "Orders",
        link: "orders",
        class: "order",
        img: "account-icon2.png"
    },
    {
        text: "Moyens de paiement",
        text_en: "Payment methods",
        link: "cardsDetails",
        class: "cards",
        img: "account-icon3.png"
    },
    {
        text: "Adresses",
        text_en: "Addresses",
        link: "addresses",
        class: "addresses",
        img: "account-icon4.png"
    },
    {
        text: "Mes scans",
        text_en: "My scans",
        link: "scans",
        class: "scans",
        img: "account-icon5.png"
    },
    {
        text: "Favoris & Enregistrements",
        text_en: "Favorites & Savings",
        link: "favorites",
        class: "",
        img: "account-icon6.png"
    },
    {
        text: "Mes avis client",
        text_en: "Customer reviews",
        link: "reviews",
        class: "reviews",
        img: "account-icon7.png"
    },
    {
        text: "Mes ordonnances",
        text_en: "My prescriptions",
        link: "prescriptions",
        class: "prescriptions",
        img: "account-icon8.png"
    }
]
}