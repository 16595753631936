<template>

	<template v-if="appView!=true">
		<div class="faq-menu contenudesk" v-if="language=='fr'">
			<a :class="{active:page=='cgv'}"  @click="$router.push({ name: 'cgv' })" ><img v-if="page=='cgv'" src="../../../../public/assets/images/arrow2.png" width="56" /><img src="../../../../public/assets/images/arrow5.png" v-else width="56" /> CGV</a>
			<a :class="{active:page=='faq'}"  @click="$router.push({ name: 'faq' })" ><img v-if="page=='faq'" src="../../../../public/assets/images/arrow2.png" width="56" /><img src="../../../../public/assets/images/arrow5.png" v-else width="56" /> FAQ</a>
			<a @click="$router.push({ name: 'confidentiality' })" :class="{active:page=='policy'}" ><img v-if="page=='policy'" src="../../../../public/assets/images/arrow2.png" width="56" /><img v-else src="../../../../public/assets/images/arrow5.png" width="56" /> Politique de confidentialité</a>
		</div>
		<div class="faq-menu contenudesk" v-else>
			<a :class="{active:page=='cgv'}"  @click="$router.push({ name: 'cgv' })" ><img v-if="page=='cgv'" src="../../../../public/assets/images/arrow2.png" width="56" /><img src="../../../../public/assets/images/arrow5.png" v-else width="56" /> CGV</a>
			<a :class="{active:page=='faq'}"  @click="$router.push({ name: 'faq' })" ><img v-if="page=='faq'" src="../../../../public/assets/images/arrow2.png" width="56" /><img src="../../../../public/assets/images/arrow5.png" v-else width="56" /> FAQ</a>
			<a @click="$router.push({ name: 'confidentiality' })" :class="{active:page=='policy'}" ><img v-if="page=='policy'" src="../../../../public/assets/images/arrow2.png" width="56" /><img v-else src="../../../../public/assets/images/arrow5.png" width="56" /> Private policy</a>
		</div>
		<div class="faq-menu-mob contenumob" v-if="language=='fr'">
			<a :class="{active:page=='cgv'}"  @click="$router.push({ name: 'cgv' })" >CGV</a>
			<a :class="{active:page=='faq'}"  @click="$router.push({ name: 'faq' })" >FAQ</a>
			<a @click="$router.push({ name: 'confidentiality' })" :class="{active:page=='policy'}" >Politique de confidentialité</a>
		</div>
		<div class="faq-menu-mob contenumob" v-else>
			<a :class="{active:page=='cgv'}"  @click="$router.push({ name: 'cgv' })" >CGV</a>
			<a :class="{active:page=='faq'}"  @click="$router.push({ name: 'faq' })" >FAQ</a>
			<a @click="$router.push({ name: 'confidentiality' })" :class="{active:page=='policy'}" >Private policy</a>
		</div>
	</template>
	<template v-else>
	<div class="faq-menu contenudesk" v-if="language=='fr'">
		<a :class="{active:page=='cgv'}"  href="/cgv?app=true"><img v-if="page=='cgv'" src="../../../../public/assets/images/arrow2.png" width="56" /><img src="../../../../public/assets/images/arrow5.png" v-else width="56" /> CGV</a>
		<a :class="{active:page=='faq'}"  href="/faq?app=true"><img v-if="page=='faq'" src="../../../../public/assets/images/arrow2.png" width="56" /><img src="../../../../public/assets/images/arrow5.png" v-else width="56" /> FAQ</a>
		<a href="/politique-de-confidentialite?app=true"  :class="{active:page=='policy'}" ><img v-if="page=='policy'" src="../../../../public/assets/images/arrow2.png" width="56" /><img v-else src="../../../../public/assets/images/arrow5.png" width="56" /> Politique de confidentialité</a>
	</div>
	<div class="faq-menu contenudesk" v-else>
		<a :class="{active:page=='cgv'}" href="/en/cgv?app=true" ><img v-if="page=='cgv'" src="../../../../public/assets/images/arrow2.png" width="56" /><img src="../../../../public/assets/images/arrow5.png" v-else width="56" /> CGV</a>
		<a :class="{active:page=='faq'}" href="/en/faq?app=true" ><img v-if="page=='faq'" src="../../../../public/assets/images/arrow2.png" width="56" /><img src="../../../../public/assets/images/arrow5.png" v-else width="56" /> FAQ</a>
		<a  href="/en/politique-de-confidentialite?app=true" :class="{active:page=='policy'}" ><img v-if="page=='policy'" src="../../../../public/assets/images/arrow2.png" width="56" /><img v-else src="../../../../public/assets/images/arrow5.png" width="56" /> Private policy</a>
	</div>
	<div class="faq-menu-mob contenumob" v-if="language=='fr'">
		<a href="/cgv?app=true" :class="{active:page=='cgv'}"   >CGV</a>
		<a href="/faq?app=true" :class="{active:page=='faq'}"  >FAQ</a>
		<a href="/politique-de-confidentialite?app=true" :class="{active:page=='policy'}" >Politique de confidentialité</a>
	</div>
	<div class="faq-menu-mob contenumob" v-else>
		<a href="/en/cgv?app=true"  :class="{active:page=='cgv'}"  >CGV</a>
		<a href="/en/faq?app=true" :class="{active:page=='faq'}" >FAQ</a>
		<a href="/en/politique-de-confidentialite?app=true" :class="{active:page=='policy'}" >Private policy</a>
	</div>
	</template>

</template>
<script>
import menuData from '../../../data/account/menu_data';

export default {
    name: "CGVmenu",
	props:["page","language","appView"],
	data(){
        const {items} = menuData
        return{
            items
        }
    }
    
}
</script>

<style scoped>
@media screen and (max-width:1025px)
{
	.faq-menu.contenudesk
	{
		display: none !important;
	}
	.faq-menu-mob a.active {
		border-bottom:1px solid black;
		font-weight: 600;
	}
	.faq-menu-mob a 
	{
		font-size:14px;
		text-transform: uppercase;
		text-align: center;
		padding-bottom:10px;
		color:#000;
		
		text-decoration: none;
		
	}
	.faq-menu-mob 
	{
		max-width: 300px;
		margin-left: auto;
		margin-right: auto;
		margin-bottom:20px;
		justify-content: space-between;
		align-items: stretch;
		width:100%;
		border-bottom:1px solid rgb(180, 180, 180);
		display: flex !important;
	}
}

.contenumob {
	display:none;
}
body .faq-menu a img {
	display: inline-block;
	position: relative;
	top:10px;
	margin-right:20px;
}
.faq-menu
{
	margin-top:50px !important;
	display:flex;
}
body .faq-menu a.active 
{
	font-weight: 600;

}
body .faq-menu a
{
	cursor: pointer;
	margin-right:50px;
	text-decoration:none;
	color:#000;
	
	font-size:48px;

}
body .faq-menu
{
	margin-left:auto;
	margin-right:auto;
	max-width:100% !important;
	vertical-align:top;
	width:100% !important;
	
	padding-top:20px;
	padding-bottom:50px;
	
}
</style>